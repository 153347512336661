/* global angular, config, moment, gData, Promise*/

'use strict';

angular.module('managerApp').controller('NavbarController', (
        $scope, $rootScope, $location, $cookies, $timeout, $mdDialog, toast,
        hotkeys, Auth, assistancePopup, terminals, discounts, $_tablets,
        smsService, smsCounter, $_webapps) => {

    $scope.config = config;
    $scope.gData = gData;
    $scope.statuses = $scope.gData.statuses;

    $scope.showLogoutWarning = false;
    $scope.showConnectionLostWarning = false;
    $scope.currentUser = Auth.getCurrentUser();
    $scope.isLoggedIn = Auth.isLoggedIn;

    const pendingOnlyProceduresCookie = 'pagination.procedures.pendingOnly';
    const itemsPerPageCookie = 'pagination.procedures.itemsPerPage';

    $scope.currentPath = $location.path();
    $scope.showSearchPanel = false;
    $scope.searchType = null;
    $scope.searhcMethod = 'get-procedures';

    $scope.device = null;

    $scope.isUser = $scope.currentUser ? $scope.currentUser.role && ['admin', 'user'].includes($scope.currentUser.role) : null;

//------------------------------------------------------------------------------

    const path = $location.path();
    const PROCEDURES_DIR = config.app.proceduresDir;
    const TEMPLATES_DIR = config.app.templatesDir;
    const DEFAULT_ERROR_MESSAGE = config.app.defaultErrorMessage;

//------------------------------------------------------------------------------

    $rootScope.$on('connection-lost', function (e) {
        $scope.showConnectionLostWarning = true;
        $scope.$apply();
    });
    $rootScope.$on('connected', function (e) {
        $scope.showConnectionLostWarning = false;
        $scope.$apply();
    });

//--------------------------------------------------------------- fab speed dial

    if ($scope.isUser === true) {
        $scope.isOpen = true;
        $scope.selectedMode = 'md-fling';
        $scope.selectedDirection = 'left';
        $scope.showSMSPopup = () => {
            if ($scope.isUser === true) {
                $mdDialog.show({
                    templateUrl: TEMPLATES_DIR + 'send.sms.html',
                    parent: angular.element(document.body),
                    fullscreen: true,
                    controller: ($scope, $mdDialog, toast) => {
                        $scope.message = null;
                        $scope.selectedMessage = null;
                        $scope.smsCounter = null;
                        $scope.sms = {
                            phone: null,
                            message: null
                        };
                        $scope.defaultMessages = [
                            "Votre dossier est incomplet." + "\n" + "Veuillez reprendre votre " +
                                    "démarche sur notre application mobile Réflexe Carte Grise " +
                                    "ou sur l'une de nos bornes et la finaliser.",
                            "Vous avez apparemment entamé une démarche sur le site de l'ANTS ce " +
                                    "qui nous bloque pour le traitement de votre demande, " +
                                    "veuillez alors annuler la démarche ANTS.",
                            "Vous pouvez dès à présent reprendre votre démarche sur notre " +
                                    "application mobile Réflexe Carte Grise ou sur l'une de " +
                                    "nos bornes et la finaliser.",
                            "Carte grise non signée par le vendeur." + "\n" + "Veuillez reprendre votre " +
                                    "démarche sur notre appli Réflexe Carte Grise ou sur l'une de " +
                                    "nos bornes et la finaliser.",
                            "Vous pouvez nous joindre par e-mail sur assistance@reflexecartegrise.fr",
                            "Vous pouvez télécharger notre appli sur : https://reflexecartegrise.fr/appli.php"
                        ];
                        $scope.onMessageChanged = (fromList = false) => {
                            if (fromList === true) {
                                $scope.sms.message = $scope.selectedMessage;
                                $scope.selectedMessage = null;
                            }
                            if ($scope.sms.message !== '') {
                                $scope.smsCounter = smsCounter.count($scope.sms.message);
                            }//
                        };
                        $scope.closeDialog = () => {
                            $mdDialog.hide();
                        };
                        $scope.run = () => {
                            smsService.send($scope.sms).then((result) => {
                                $mdDialog.hide();
                                if (result) {
                                    toast.show(result.message, 'success');
                                }
                            }).catch((err) => {
                                console.log(err);
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        };
                    }
                }).then(() => {
                    /* dialog closed */
                });
            }
            ;
        }
        $scope.clearCache = () => {
            if (window.confirm('Voulez-vous vraiment vider le cache de votre session ?')) {
                let cookiesToKeep = [
                    'x-from', config.tmsPopup, config.auth.token, config.auth.me
                ];
                for (let cookie in $cookies.getAll()) {
                    if (!cookiesToKeep.includes(cookie)) {
                        $cookies.remove(cookie);
                    }
                }
                localStorage.clear();
                window.location.reload();
            }
        };
    }

//----------------------------------------------------------------------- search

    let availableSearchTypes = ['procedures', 'refunds', 'terminals', 'tablets'];

    let searchItemsPerPage = $cookies.get(itemsPerPageCookie) || 25;
    let maxDate = new Date();
    maxDate.setHours(23, 59, 59);

    $scope.search = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: searchItemsPerPage,
        totalItems: 0,
        maxDate: maxDate,
        filters: {},
        proceduresType: null,
        pendingOnlyProcedures: $cookies.get(pendingOnlyProceduresCookie) === 'true',
        pageChanged: function (loadData) {
            $cookies.put(pendingOnlyProceduresCookie, this.pendingOnlyProcedures);
            $cookies.put(itemsPerPageCookie, this.itemsPerPage);
            if (loadData === true) {
                $rootScope.$broadcast($scope.searhcMethod, {
                    proceduresType: this.proceduresType
                });
            }
        }
    };

    if (typeof $cookies.get(pendingOnlyProceduresCookie) === 'undefined') {
        $cookies.put(pendingOnlyProceduresCookie, false);
    }

    $rootScope.$on('$routeChangeSuccess', function () {
        $scope.currentPath = $location.path();
        let pathParts = $scope.currentPath.split('/');
        if (pathParts.length > 1) {
            $scope.searchType = pathParts[1];
            if (['terminals', 'tablets'].indexOf($scope.searchType) > -1) {
                $scope.device = ($scope.searchType + '').slice(0, -1);
                setTimeout(() => {
                    $scope.partnersList = $scope[$scope.device + 'Partners'];
                });
            }
            let proceduresType = pathParts[2];
            $scope.searhcMethod = 'get-' + $scope.searchType;
            if (availableSearchTypes.indexOf($scope.searchType) < 0) {
                removeHotkyes();
                $scope.showSearchPanel = false;
            } else {
                $scope.search.proceduresType = proceduresType;
                initHotkeys();
            }
        } else {
            removeHotkyes();
            $scope.showSearchPanel = false;
        }
    });

    $rootScope.$on('show-search-panel', function (e, data) {
        $scope.showSearchPanel = true;
    });

    $rootScope.$on('get-ants-available', function (e, data) {
        $scope.hasANTSProcedures = data.hasANTSProcedures;
        if ($scope.hasANTSProcedures === true) {
            $scope.hasANTSGetPermission = Auth.hasPermission('ANTS', 'GET');
        }
    });

    $scope.hideSearchPanel = () => {
        $scope.showSearchPanel = false;
    };

    $scope.runSearch = () => {
        if ($scope.search.searhcMethod) {
            $rootScope.$broadcast($scope.search.searhcMethod);
        } else {
            $rootScope.$broadcast($scope.searhcMethod, $scope.search.proceduresType);
        }
    };
    $scope.resetSearch = () => {
        $scope.search.filters = {};
    };

    $scope.toggleMenu = () => {
        if ($scope.isLoggedIn()) {
            $scope.$emit('toggleMenu', {});
        }
    };

    $scope.showLogoutConfirm = ($event) => {
        $mdDialog.show({
            targetEvent: $event,
            parent: angular.element(document.body),
            templateUrl: 'components/navbar/logout.html',
            controller: ($scope, $location, $mdDialog) => {
                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        $location.path('/logout');
                    }
                    $mdDialog.cancel();
                };
            }
        });
    };

    $scope.showAssistancePopup = () => {
        if ($scope.currentUser.isAgent === true) {
            assistancePopup.show($scope.currentUser._id).then(() => {
                toast.show('La demande d\'assistance a bien été enregistrée.', 'success');
            }).catch((err) => {
                if (err !== null) {
                    toast.show(err && err.message ? err.message : 'Une erreur est survenue lors du traitement de votre demande.');
                }
            });
        }
    };

    $scope.getDiscounts = (code) => {
        return getDiscountsByCode(code);
    };
    function getDiscountsByCode(code) {
        return discounts.get({
            code: code
        }).then((codes) => {
            return Promise.resolve(codes);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }

    $scope.$on('IdleStart', function () {
        // the user appears to have gone idle
        $scope.showLogoutWarning = true;
        $scope.$apply();
    });

    $scope.$on('IdleWarn', function (e, countdown) {
        $scope.countdown = countdown;
        $scope.$apply();
        // follows after the IdleStart event, but includes a countdown until the user is considered timed out
        // the countdown arg is the number of seconds remaining until then.
        // you can change the title or display a warning dialog from here.
        // you can let them resume their session by calling Idle.watch()
    });

    $scope.$on('IdleTimeout', function () {
        $scope.showLogoutWarning = false;
        $scope.$apply();
        setTimeout(() => {
            $location.path('/logout');
            $scope.$apply();
        });
        // the user has timed out (meaning idleDuration + timeout has passed without any activity)
        // this is where you'd log them
    });

    $scope.$on('IdleEnd', function () {
        $scope.showLogoutWarning = false;
        $scope.$apply();
        // the user has come back from AFK and is doing stuff. if you are warning them, you can use this to hide the dialog
    });

    $scope.$on('Keepalive', function () {
        // do something to keep the user's session alive
    });

//------------------------------------------------------------------------------

    function removeHotkyes() {
        hotkeys.del('esc');
        hotkeys.del('f3');
        hotkeys.del('ctrl+f');
    }

    function initHotkeys() {
        removeHotkyes();
        hotkeys.add({
            combo: ['f3', 'ctrl+f'],
            callback: (event, hotkey) => {
                event.preventDefault();
                setShowSearhPanel(!$scope.showSearchPanel);
            }
        });
    }

    function setShowSearhPanel(show = true) {
        if (show === true) {
            $scope.showSearchPanel = true;
            hotkeys.add({
                combo: 'esc',
                callback: function () {
                    $scope.showSearchPanel = false;
                }
            });
        } else {
            $scope.showSearchPanel = false;
            hotkeys.del('esc');
        }//
    }

    function getSearchFilters() {
        let search = $location.search();
        for (let i in search) {
            if (i === 'date_from' || i === 'date_to') {
                $scope.search.filters[i] = new Date(search[i]);
            } else {
                if (search[i] !== '') {
                    $scope.search.filters[i] = search[i];
                }
            }
        }
    }

    function updateCurrentURL(resetOnly = false) {
        const url = new URL(window.location);
        url.search = '';
        if (resetOnly === true) {
            url.search = '';
            return url;
        }
        if ($scope.search.filters) {
            let filters = $scope.search.filters;
            for (let i in filters) {
                if (i === 'date_from' || i === 'date_to') {
                    url.searchParams.set(i, moment(filters[i]).format(config.date.defaultFormat));
                } else if (i === 'DiscountCode') {
                    if (filters.DiscountCode !== null) {
                        url.searchParams.set('discountCode', filters.DiscountCode._id);
                    }
                } else {
                    if (filters[i] !== '') {
                        url.searchParams.set(i, filters[i]);
                    }
                }
            }
        }
        return url;
    }

    function getTerminalPartners() {
        let data = JSON.parse(localStorage.getItem('navbar.terminalPartners'));
        if (!data) {
            terminals.get({
                partners: 1,
                namesIdsOnly: 1
            }).then((data) => {
                localStorage.setItem('navbar.terminalPartners', JSON.stringify(data));
                $scope.terminalPartners = data.items;
                $scope.$apply();
            }).catch((err) => {
                //
            });
        } else {
            $scope.terminalPartners = data.items;
        }
        $timeout(() => {
            if ($scope.terminalPartners && $scope.terminalPartners[0] && ($scope.terminalPartners[0].Client || {})._id !== 'all') {
                $scope.terminalPartners.unshift({
                    _id: 'all',
                    Client: {
                        company: 'Tous les partenaires borne'
                    }
                });
            }
        }, 5000);
    }

    function getTabletPartners() {
        let params = {
            partners: 1,
            namesIdsOnly: 1
        };
        let data = JSON.parse(localStorage.getItem('navbar.tabletPartners'));
        if (!data) {
            $_tablets.get(params).then((data) => {
                localStorage.setItem('navbar.tabletPartners', JSON.stringify(data));
                $scope.tabletPartners = data.items;
                $scope.$apply();
            }).catch((err) => {
                //
            });
        } else {
            $scope.tabletPartners = data.items;
        }
        $timeout(() => {            
            if ($scope.tabletPartners && $scope.tabletPartners[0] && ($scope.tabletPartners[0].Client || {})._id !== 'all') {
                $scope.tabletPartners.unshift({
                    _id: 'all',
                    Client: {
                        company: 'Tous les partenaires tablette'
                    }
                });
            }
        }, 2500);
    }
    
    function getWebAppPartners() {
        let params = {
            partners: 1,
            namesIdsOnly: 1
        };
        let data = JSON.parse(localStorage.getItem('navbar.webAppPartners'));
        if (!data) {
            $_webapps.get(params).then((data) => {
                localStorage.setItem('navbar.webAppPartners', JSON.stringify(data));
                $scope.webAppPartners = data.items;
                $scope.$apply();
            }).catch((err) => {
                //
            });
        } else {
            $scope.webAppPartners = data.items;
        }
        $timeout(() => {
            console.log("==>", $scope.webAppPartners);
            if ($scope.webAppPartners && $scope.webAppPartners[0] && $scope.webAppPartners[0].Client._id !== 'all') {
                $scope.webAppPartners.unshift({
                    _id: 'all',
                    Client: {
                        company: 'Tous les partenaires web app'
                    }
                });
            }
        }, 5000);
    }    

    function getTerminals() {
        let _terminals = JSON.parse(localStorage.getItem('navbar.terminals'));
        if (!_terminals) {
            terminals.getSimple().then((data) => {
                localStorage.setItem('navbar.terminals', JSON.stringify(data));
                $scope.terminalsItems = data;
                $scope.$apply();
            }).catch((err) => {
                $scope.error = err;
            });
        } else {
            $scope.terminalsItems = _terminals;
        }
        $timeout(() => {
            if ($scope.terminalsItems && $scope.terminalsItems[0] && $scope.terminalsItems[0]._id !== 'all') {
                $scope.terminalsItems.unshift({
                    _id: 'all',
                    name: 'Toutes les bornes'
                });
            }
        }, 5000);
    }

    function getTablets() {
        let _tablets = JSON.parse(localStorage.getItem('navbar.tablets'));
        if (!_tablets) {
            $_tablets.getSimple().then((data) => {
                localStorage.setItem('navbar.tablets', JSON.stringify(data));
                $scope.tabletsItems = data;
                $scope.$apply();
            }).catch((err) => {
                $scope.error = err;
            });
        } else {
            $scope.tabletsItems = _tablets;
        }
        $timeout(() => {
            if ($scope.tabletsItems && $scope.tabletsItems[0] && $scope.tabletsItems[0]._id !== 'all') {
                $scope.tabletsItems.unshift({
                    _id: 'all',
                    name: 'Toutes les tablettes'
                });
            }
        }, 5000);
    }

    function getWebApps() {
        let _webApps = JSON.parse(localStorage.getItem('navbar.webApps'));
        if (!_webApps) {
            $_webapps.getSimple().then((data) => {
                localStorage.setItem('navbar.webApps', JSON.stringify(data));
                $scope.webAppsItems = data;
                $scope.$apply();
            }).catch((err) => {
                $scope.error = err;
            });
        } else {
            $scope.webAppsItems = _webApps;
        }
        $timeout(() => {
            if ($scope.webAppsItems && $scope.webAppsItems[0] && $scope.webAppsItems[0]._id !== 'all') {
                $scope.webAppsItems.unshift({
                    _id: 'all',
                    name: 'Toutes les web apps'
                });
            }
        }, 2500);
    }

    function initData() {
        getWebApps();
        getTablets();
        getTerminals();
        getWebAppPartners();
        getTabletPartners();
        getTerminalPartners();
    }

//------------------------------------------------------------------------------

    if (!config.isPartnerSession && $scope.isLoggedIn()) {
        initData();
    }
    getSearchFilters();

});
